<template lang="pug">
v-card(v-if="Object.keys(sailorDocument).length" :loading="isLoading").pa-4
  v-card-title.d-flex.justify-space-between.w-100
    div {{ $t(`${Object.keys(sailorDocument.behavior)[0]}-statementISC`).toUpperCase() }}
      span(v-if="checkAccess('backOffice')") (ID: {{ sailorDocument.id }})
    ControlButtons(:controlButtons="controlButtons").justify-end
  v-divider
  v-card-text
    StatementISCInfo(
      v-if="sailorDocument.behavior.viewInfoBlock"
      :sailorDocument="sailorDocument"
      :comments="comments")

    div(v-if="!sailorDocument.behavior.viewAddBlock").d-flex.align-items-center.flex-wrap.p-2
      div(
        v-for="photo of sailorDocument.files"
        :key="photo")
        a(
          :href="photo"
          target="_blank").d-block.mx-2
          div.photoDocument-block__image
            embed(
              v-if="photo.includes('.pdf')"
              :src="photo"
              width="324"
              height="324"
            ).d-block
            img(
              v-else
              :src="photo"
              alt="photo")
    StatementISCEdit(
      v-if="sailorDocument.behavior.viewEditBlock"
      :sailorDocument="sailorDocument")
</template>

<script>
import { viewDetailedComponent } from '@/mixins/main'
import { checkAccess } from '@/mixins/permissions'
import { mapState, mapActions } from 'vuex'

export default {
  name: 'StatementISCDocument',
  components: {
    AddSailor: () => import('@/views/AddSailor/AddSailor.vue'),
    StatementISCInfo: () => import('./StatementISCInfo'),
    StatementISCEdit: () => import('./StatementISCEdit'),
    ControlButtons: () => import('@/components/atoms/ControlButtons')
  },
  data () {
    return {
      viewDetailedComponent,
      checkAccess,
      comments: [],
      controlButtons: [
        {
          id: 1,
          name: 'mdi-account-outline',
          action: () => this.$router.push(`/sailor/${this.sailorDocument.sailor_id}`),
          checkAccess: () => this.sailorDocument.status_document === 41,
          color: 'green',
          tooltip: 'documentIconControl.go'
        },
        {
          id: 2,
          name: 'mdi-information-outline',
          action: () => viewDetailedComponent(this.sailorDocument, 'viewInfoBlock'),
          checkAccess: () => this.sailorDocument.status_document === 34,
          color: '#42627e',
          tooltip: 'documentIconControl.info'
        },
        {
          id: 3,
          name: 'mdi-pen',
          action: () => viewDetailedComponent(this.sailorDocument, 'viewEditBlock'),
          checkAccess: () => this.sailorDocument.status_document === 34,
          color: '#42627e',
          tooltip: 'documentIconControl.edit'
        },
        {
          id: 4,
          name: 'mdi-close',
          action: () => this.$router.go(-1),
          color: '#42627e',
          tooltip: 'documentIconControl.close'
        }
      ]
    }
  },
  computed: {
    ...mapState({
      labelName: state => state.main.lang === 'en' ? 'name_eng' : 'name_ukr',
      sailorDocument: state => state.sailor.statementISCById,
      isLoading: state => state.sailor.isLoading
    })
  },
  mounted () {
    this.loadData()
  },
  methods: {
    ...mapActions(['getStatementISCById']),

    async loadData () {
      await this.getStatementISCById({ ...this.$route.params })
    }
  }
}
</script>
